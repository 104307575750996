import { useState, useEffect } from 'react';

const Typedanimation = ({ text, delay, infinite }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentArrayIndex, setCurrentArrayIndex] = useState(0)

  useEffect(() => {
    let timeout;
    
    if(currentArrayIndex < text.length){
      if (currentIndex <= text[currentArrayIndex].length) {
        timeout = setTimeout(() => {
            setCurrentText(prevText => prevText + text[currentArrayIndex][currentIndex]);
            setCurrentIndex(prevIndex => prevIndex + 1);
        }, delay);
    
        } 
        else if (currentIndex > text[currentArrayIndex].length){
          setCurrentIndex(0);
          setCurrentText('');
          setCurrentArrayIndex(prevArrayIndex => prevArrayIndex + 1);
        }
    }
    else if(infinite){
      setCurrentIndex(0);
      setCurrentText('');
      setCurrentArrayIndex(0);
    }


    return () => clearTimeout(timeout);
  }, [currentArrayIndex,currentIndex, delay, infinite, text]);

  return <span>{currentText}</span>;
};

export default Typedanimation;