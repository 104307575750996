const ProgressBar = ({ progressPercentage }) => {
    return (
        <div className='h-1 w-full bg-gray-300'>
            <div
                style={{ width: `${progressPercentage}%` }}
                className={`h-full ${progressPercentage < 0 ? 'bg-yellow-600' : 'bg-yellow-600'} md:bg-yellow-600`}>
            </div>
        </div>
    );
};

export default ProgressBar;
