import React from "react";

const Contact = () => {
    return (
        <section
            className="min-h-screen bg-cover bg-no-repeat text-white px-5 py-32 bg-opacity-70"
            id="contact"
            style={{
                backgroundImage: 'url("./assets/background1.jpg")', // Replace with your actual image path
                backgroundAttachment: 'fixed', // Fix the background image
            }}
        >
            <div className="text-center md:w-[60%] mx-auto text-white">
                <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[200px] mx-auto border-indigo-600 pb-2">
                    Contact Me
                </h2>
                <p>
                    I am actively seeking  full-time Full stack software development and cloud roles. With a strong background in full stack software development and a passion for cloud technologies, I am eager to contribute my skills to a dynamic team.

                    If you have a suitable opportunity that aligns with my expertise, I welcome discussions to explore how I can make a positive impact on your projects. Please feel free to reach out to me via email or phone call.

                    Thank you for considering my application. I look forward to the possibility of working together.
                </p>

                <p className="py-2">
                    <span className="font-bold">Email:</span> balachandrangeethika@gmail.com
                </p>
                <p className="py-2">
                    <span className="font-bold">Phone:</span> +1 4372633305
                </p>
            </div>
        </section>
    );
};

export default Contact;
