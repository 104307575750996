import React from "react";
import ProgressBar from "../progressbar/progress";
import skillsData from './data'

import './style.css'

const Skills = () => {
    return (
        <section className="bg-primary text-white px-5 py-32 min-h-screen" id="skills">
            <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
                <div className="about-info">
                    <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
                        My Skills
                    </h2>

                    <div />
                    <ul>
                        {skillsData.map((item) => (
                            <li key={item.skill}>
                                <div className="flex justify-between items-top">
                                    <span>{item.skill}</span>

                                    <span>{item.value} %</span>
                                </div>
                                <ProgressBar progressPercentage={item.value} />
                                <br/>
                            </li>
                        ))}
                    </ul>

                </div>

                <div className="about-img">
                    <img
                        src="./assets/settings.png"
                        alt="coding illustration"
                        className="lgw-[80%] md:ml-auto"
                    />
                </div>
            </div>
        </section>
    );
};

export default Skills;
