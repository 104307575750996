const skillsData = [
    
    {
        skill: "React",
        value: 80
    },
    {
        skill: "Angular",
        value: 80
    },
    {
        skill: "Javascript",
        value: 80
    },
    {
        skill: "Typescript",
        value: 80
    },
    {
        skill: ".Net",
        value: 70
    },
    {
        skill: "Python",
        value: 90
    },
    {
        skill: "Databases(SQL and NoSQL)",
        value: 80
    },
    {
        skill: "AWS",
        value: 90
    },
    {
        skill: "Cloud Computing",
        value: 90
    },
    {
        skill: "DevOps",
        value: 40
    },
    {
        skill: "HTML",
        value: 50
    },
    {
        skill: "CSS",
        value: 50
    }
]

export default skillsData;
   