import React from "react";

const About = () => {
  return (
    <section
      className=" min-h-screen bg-cover bg-no-repeat text-white px-5 py-32 bg-opacity-70"
      id="about"
      style={{
        backgroundImage: 'url("./assets/about.jpg")', // Replace with your actual image path
        backgroundAttachment: 'fixed', // Fix the background image
      }}
    >
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
        <div className="about-info">
          <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
            About Me
          </h2>

          <p className="pb-5 font-bold">
            Hello, I'm Geethika Balachandran, a passionate software developer with over 3 years of hands-on experience. I derive immense joy from the world of coding and software development. As an aspiring cloud engineer, I am dedicated to exploring and mastering cutting-edge cloud computing technologies and concepts.
          </p>
          <p className="font-bold">
            I specialize in JavaScript, Python, and cloud technologies like AWS, and I have hands-on experience in frontend technologies like React. Holding a master's degree in Computer Science and Engineering from TKM College of Engineering, I am committed to academic excellence.
            Adding to my educational accomplishments, I have successfully completed an Ontario College Graduate Certificate from Loyalist College in Toronto, enhancing my skills and knowledge in the dynamic field of cloud computing.
          </p>
          <p className="font-bold">
            I am a firm believer in the power of doing over telling. With a continuous learning mindset and a self-starter approach, I strive to stay at the forefront of industry trends. I am motivated by the philosophy that self-motivation and hard work are the cornerstones of success.
          </p>
        </div>
      </div>
    </section>
  );
};


export default About;
