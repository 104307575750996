import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import Header from './components/header/header';
import MainComponent from './components/main/main';

function App() {
  return (
    <>
    <Header/>
    <Router>
      <Routes>
        <Route path="/" element={<MainComponent />} />
      </Routes>

    </Router></>
  );
}

export default App;
