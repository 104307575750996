import React from "react";
import Typedanimation from '../typed-animation/typed-animation'
import { useSpring, animated } from "react-spring";
import {
  AiOutlineLinkedin
} from "react-icons/ai";

const Home = () => {
  const props = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: async (next, cancel) => {
      while (1) {
        await next({ opacity: 1, transform: "scale(1)" });
        await next({ opacity: 0.8, transform: "scale(0.95)" });
      }
    },
    config: { tension: 150, friction: 10 },
  });


  return (
    <section className="bg-primary px-5 text-white py-32 min-h-screen">
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
        <div className="hero-info pb-5 md:pb-0">
          <h1 className="text-1xl lg:text-2xl">
            Hi there !, I am <br />
          </h1>
          <h1 className="text-4xl lg:text-6xl">
            Geethika Balachandran <br />
          </h1>
          <h1 className="text-2xl lg:text-4xl">And I am a <Typedanimation text={["FULL-STACK DEVELOPER", "CLOUD ENGINEER"]} delay={300} infinite></Typedanimation></h1>
          <div className="flex py-5 ">
            <a
              href="https://www.linkedin.com/in/geethika-balachandran-a76b65118"
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineLinkedin size={40} />{" "}
            </a>

          </div>
        </div>

        <div className="hero-img">
          <animated.img
            src="./assets/homePage.png"
            alt="coding illustration"
            className="lg:w-[80%] ml-auto"
            style={props}
          />

        </div>
      </div>
    </section>
  );
};

export default Home;