const projectsData = [
    {
        title: "VSSC",
        domain: "Aerospace",
        technologies: "Frontend Development using Angular 9",
        
    },
    {
        title: "Dreamr",
        domain: "Event Management",
        technologies: "Front-end development using React JS, Serverless Development using NodeJs AWS SAM, Lambda and other AWS services.",
        
    },
    {
        title: "Gordian",
        domain: "Industrial",
        technologies: "Frontend Development using Angular 9",
        
    },
    {
        title: "Crane",
        domain: "Industrial",
        technologies: "Front-end Development using Vue JS, Back-end Development using Python and worked On AWS Cloud services.",
        
    }
    // Add more projects as needed
];
export default projectsData;