import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return (
        <div className="py-4 text-center bg-secondery text-white">
            Developed with passion and love <FontAwesomeIcon icon={faHeart} /> &copy; 2023 - 2024 All right reserved
        </div>
    );
};
export default Footer;
