import React from "react";
import Home from "../home/home";
import About from "../about/about";
import Skills from "../skills/skills";
import Projects from "../projects/projects";
import Contact from "../contact/contact";
import Footer from "../footer/footer";

export default function MainComponent() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">

      <Home />
      <About />
      <Skills />
      <Projects/>
      <Contact/>
      <Footer/>

    </main>
  );
}