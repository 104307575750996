import React, { useRef } from "react";

import projectsData from "../projects/projectData";


const Projects = () => {
   
    const projectListRef = useRef();

    const scrollLeft = () => {
        if (projectListRef.current) {
            projectListRef.current.scrollLeft -= projectListRef.current.clientWidth;
        }
    };

    const scrollRight = () => {
        if (projectListRef.current) {
            projectListRef.current.scrollLeft += projectListRef.current.clientWidth;
        }
    };

    return (
        <section
            className="min-h-screen bg-cover bg-no-repeat text-white px-5 py-32 bg-opacity-70"
            id="projects"
            style={{
                backgroundImage: 'url("./assets/background12.jpg")', // Replace with your actual image path
                backgroundAttachment: 'fixed', // Fix the background image
            }}
        >
            <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
                <div className="about-info mb-5">
                    <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
                        Projects
                    </h2>

                    <p className="pb-5">
                        These are some of my projects I got the oppurtunity to work and explore my skills.
                    </p>
                </div>

                <div className="about-img"></div>
            </div>
            <div className="projects container mx-auto overflow-x-auto p-4">
                <div
                    ref={projectListRef}
                    className="flex flex-nowrap gap-10"
                    style={{ scrollBehavior: "smooth" }}
                >
                    {projectsData.map((project, i) => (
                        <div key={i} className="relative flex-shrink-0" style={{ minWidth: "300px" }}>
                            <div className="bg-gray-100 p-6 rounded-md shadow-lg mb-6">
                                <h2 className="text-black font-bold mb-4 border-b-[5px] w-[180px] border-indigo-600" >{project.title}</h2>
                                <p className="text-gray-600 mb-4">
                                    <strong>Domain: </strong>{project.domain}
                                </p>
                                <p className="text-gray-700 mb-2">
                                    <strong>Technologies:</strong> {project.technologies}
                                </p>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default Projects;
